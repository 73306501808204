<template>
    <section class="d-flex">
        <div class="col-4">
            <div class="bg-white br-12 py-3">
                <div class="row mx-0 j-center">
                    <img :src="convenio.logo" class="obj-cover br-4" width="148" height="148" />
                    <p class="text-center col-12 f-16 mt-3 text-black f-600">
                        {{ convenio.nombre }}
                    </p>
                </div>
                <div class="row mx-0 j-center mt-1 mb-2">
                    <div class="mn-pill bg-black text-white">
                        {{ convenio.cliente.cantidad_afiliados }} Afiliados
                    </div>
                </div>
                <div class="row mx-0 j-center mt-2">
                    <div class="col-auto d-flex text-gris2 f-16">
                        <i class="icon-identification f-15 text-gris2" />
                        <span class="mx-1">{{ convenio.cliente.cedula }}</span>
                    </div>
                </div>
                <div class="row mx-0 j-center">
                    <div class="col-auto d-flex text-gris2 f-16">
                        <i class="icon-building f-15 text-gris2" />
                        <span>Trabajo desde: {{ convenio.cliente.fecha_vinculacion | helper-fecha('DD MMM YYYY') }}</span>
                    </div>
                </div>
                <div class="row mx-0 j-center mt-3">
                    <div class="mn-pill bg-red text-white mr-1">
                        Deuda: {{ formatNumero(convenio.cliente.deuda) }}
                    </div>
                    <div class="mn-pill bg-green text-white ml-1">
                        Cupo: {{ formatNumero(convenio.cliente.cupo) }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <cargando v-if="cargando" />
            <p class="text-center f-18 f-600">
                Últimas liquidaciones
            </p>
            <!-- Grafica -->
            <echart v-if="convenio.grafica.length > 0" :options="ultimasLiquidaciones" />
            <div v-if="convenio.grafica.length < 1" style="position:relative;" class="d-flex justify-content-center">
                <img class="obj-cover mt-2" src="/img/no-imagenes/no_datos.svg" style="height:100%; width:100%; position:relative" />
            </div>
            <!-- Grafica -->
            <div class="row mx-0 py-4" />
            <div v-for="(data, index) in convenio.proximosCobros" :key="index" class="bg-white shadow-14 br-8 p-3 mb-3">
                <p class="text-black f-14">
                    <span class="f-500">Próximo cobro:</span> {{ data.fecha_descuento | helper-fecha('DD MMM YYYY') }}
                </p>
                <p class="text-black f-14 mt-2">
                    <span class="f-500">Corte:</span> {{ data.fecha_corte | helper-fecha('DD MMM YYYY') }}
                </p>
                <div class="row mx-0 mt-2">
                    <div class="col-auto d-middle pl-0">
                        <i class="icon-order f-15 text-black " />
                        <span class="f-500 mx-1">
                            {{ data.pedidos }}
                        </span>
                        Pedidos
                    </div>
                    <div class="col-auto d-middle text-red pl-0">
                        <i class="icon-wallet f-15 text-black " />
                        <span class="f-500 mx-1">
                            {{ formatNumero(data.cobro) }}
                        </span>
                        Deuda
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 pt-5 mt-3">
            <div class="bg-white br-12 py-3 position-relative mt-5">
                <img :src="convenio.logo" class="br-4 obj-cover border position-absolute" style="top:-110px;left:108px" width="132" height="132" />
                <p class="text-center text-black mt-3 f-16 f-600 mb-3">
                    {{ convenio.nombre }}
                </p>
                <p class="f-14 mb-2 px-3">
                    {{ convenio.mensaje }}
                </p>
                <p class="f-14 mb-4 px-3">
                    Para mayor información comunícate al:
                </p>
                <div v-for="(data, index) in convenio.contactos" :key="index" class="row mx-0 pl-4 a-center mb-3">
                    <i class="icon-phone" />
                    <div class="col px-2">
                        <p class="f-12 f-600">{{ data.nombre }}</p>
                        <p class="f-12 f-400">{{ data.contacto_telefono }}</p>
                    </div>
                </div>
            </div>
            <button type="button" class="btn-general mt-3 w-100" @click="accion = 'irTienda', ejecutarAccion()">
                Ir a la tienda
            </button>
            <button type="button" class="btn-outline mt-3 w-100 text-black" @click="accion = 'irDesvincular', confirmarDesvincular()">
                Desvincularme de convenio
            </button>
        </div>
        <!-- Partials -->
        <modal ref="modalDesvincularmeConvenio" titulo="Desvincularme de convenio" no-aceptar adicional="Desvincular" @adicional="ejecutarAccion">
            <p class="text-center f-15">
                ¿Estás seguro de desvincularte del <br /> convenio?
            </p>
        </modal>
    </section>
</template>
<script>
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-general')
export default {
    props:{
        convenio: {
            type: Object,
            default:() => {},
        },
    },
    data(){
        return {
            cargando: false,
            accion: '',
            urlAvianca: "https://getlasso.co/wp-content/uploads/Avianca-LATAM.jpg",
            ultimasLiquidaciones: {
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    alwaysShowContent: true,
                    borderColor: colorGeneral,
                    borderWidth: 1.9,
                    backgroundColor: 'rgba(255, 255, 255)',
                    textStyle: {
                        color: '#000000'
                    },
                    position: ['0%', '94%'],
                    formatter: params => {
                        let index = params[0].dataIndex;
                        let data = this.convenio.grafica[index];
                        return `
                        <span class="text-black f-14 f-500">Cobro</span>  <span>${data.fecha_cobro}</span>  <span class="px-3" />
                        <span class="text-black f-14 f-500">Corte:</span> <span>${data.fecha_corte}</span> <br />
                        <i class="icon-order f-15 text-black"></i> <span class="text-black f-600">${data.pedidos}</span> <span> Pedidos </span> <span class="px-3" />
                        <i class="icon-wallet f-15 text-black"></i> <span class="text-black f-600">${this.formatNumero(data.cobro)}</span> <span> Pago </span>
                        `
                    }
                },
                legend: {
                    data: ['Cobro', 'Pedidos']
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        interval: 0,
                        axisLine: {
                            lineStyle: {
                                color: '#DBDBDB',
                                type: 'dashed',
                                dashOffset: 8
                            }
                        },
                        axisLabel: {
                            fontSize: 12,
                            color: '#000000'
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    {
                        type: 'value',
                        interval: 0,
                        axisLine: {
                            lineStyle: {
                                color: '#DBDBDB',
                                type: 'dashed',
                                dashOffset: 8
                            }
                        },
                        axisLabel: {
                            fontSize: 12,
                            color: '#000000'
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        type: 'bar',
                        color: '#DFE4E8',
                        data: []
                    },
                    {
                        type: 'line',
                        yAxisIndex: 1,
                        data: [],
                        symbol: 'circle',
                        symbolSize: 8,
                        lineStyle: {
                            color: '#612AD5'
                        },
                        itemStyle: {
                            borderWidth: 2,
                            color: '#612AD5',
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#612AD5',
                                borderColor: '#fff',
                            },
                        },
                    }
                ]
            }
        };
    },
    mounted(){
        setTimeout(() => {
            this.armarGrafica();
        },500);
    },
    methods: {
        ejecutarAccion(){
            this.$emit('accion', this.accion);
            if(this.accion === 'irDesvincular'){
                this.$refs.modalDesvincularmeConvenio.toggle();
            }
        },

        armarGrafica(){
            this.cargando = true;

            let xAxis = this.convenio.grafica.map(e => e.fecha_grafica);
            let series = this.convenio.grafica.map(e => parseInt(e.cobro));
            let series1 = this.convenio.grafica.map(e => parseInt(e.pedidos));
            
            this.convenio.grafica.map((e) => {
                e.fecha_cobro = e.fecha_descuento;
                e.fecha_corte = e.fecha_corte;
                e.pedidos = e.pedidos;
                e.pago = e.cobro;
                return e;
            });

            this.ultimasLiquidaciones.xAxis[0].data = xAxis;
            this.ultimasLiquidaciones.series[0].data = series;
            this.ultimasLiquidaciones.series[1].data = series1;
            
            setTimeout(() => {
                this.cargando = false;
            },500);
        },

        confirmarDesvincular(){
            this.$refs.modalDesvincularmeConvenio.toggle();
        }
    }
}
</script>